import React from 'react'
import Title from './Title'

import TeamWork from '../assets/svg/about.svg'

function About() {
  return (
    <div className="section" id="about_block">
      <Title
        type="title theme"
        text="The power of the cloud"
      />
      <div className="container">
        <div className="about">
          <h2 className="subtitle w-60 ma c-medium text-center">Devs Team Cloud is a freelancing marketplace that connects top-level developers with businesses from around the world. We offer a wide selection of qualified, experienced, and talented IT freelancers to suit your needs.
          </h2>
        </div>
        <div className="s-80"></div>
        <div className="flex w-90 ma jcsa">
          <div className="w-30 flex jcc aic">
            <p className="text c-medium text-right">We have over a decade of experience in the IT industry and are always up-to-date with latest technological developments. We are a team of freelancers who work remotely and can provide our services without geographical limitations, so that is not an issue when hiring us as your IT freelancer.</p>
          </div>
          <div className="w-30 ma"><img src={TeamWork} alt="Team Work" /></div>
          <div className="w-30 flex jcc aic">
            <p className="text c-medium">The best thing about hiring a freelancer is that you can hire someone with specific expertise that matches your needs. You can also hire them on a per project basis, which makes it easier to manage the costs and time requirements when compared to hiring full-time employees.</p>
          </div>
        </div>
        <div className="s-80"></div>
        <div className="flex w-90 ma jcsa">
          <div className="w-40">
            <h3 className="subtitle text-right">World-class talent at your fingertips</h3>
            <p className="text c-medium text-right">We offer access to some of the best freelance developers in the world at competitive rates. No matter what language or skill set you need, we have it on our team!</p>
          </div>
          <div className="w-40">
            <h3 className="subtitle text-left">Choose a plan for your needs</h3>
            <p className="text c-medium text-left">Whether you're just starting out and need help with small projects or want to hire full-time staff, we have the plan for you. Get in touch with our team today to find out more about what's available!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
