import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import Title from './Title'
import pic1 from '../assets/img/services/s001.png'
import pic2 from '../assets/img/services/s002.png'
import pic3 from '../assets/img/services/s003.png'
import pic4 from '../assets/img/services/s004.png'
import pic5 from '../assets/img/services/s005.png'
import pic6 from '../assets/img/services/s006.png'
import pic7 from '../assets/img/services/s007.png'
import pic8 from '../assets/img/services/s008.png'
import pic9 from '../assets/img/services/s009.png'

function Cases() {

  const slides = [
    {
      id: 1,
      pic: pic1,
      title: 'Order Now',
      text: `So you have an idea for a web app, but you don't know how to code it? That's where we come in. Our service development code team can develop custom-made web applications/services according to your specifications. We're able to do this by leveraging our experience and skills in a variety of programming languages. Get your idea off the ground with us and make it successful!`
    },
    {
      id: 2,
      pic: pic2,
      title: 'Order Now',
      text: `We have a team of engineers and designers, who can handle any development project you throw at them. We provide a variety of apps and services including mobile app design and development, website design, logo design, web development, and much more. What's more, we offer the best prices on the market! We guarantee our work will be exceptional.`
    },
    {
      id: 3,
      pic: pic3,
      title: 'Order Now',
      text: `You know that feeling of having to search for design elements separately? Design - All in One eliminates this problem with its seamless integration. From a simple logo to an entire website design, our templates are professionally designed and can be customized easily. We're still small, but we're growing and hiring constantly.`
    },
    {
      id: 4,
      pic: pic4,
      title: 'Order Now',
      text: `So you have an idea for a web app, but you don't know how to code it? That's where we come in. Our service development code team can develop custom-made web applications/services according to your specifications. We're able to do this by leveraging our experience and skills in a variety of programming languages. Get your idea off the ground with us and make it successful!`
    },
    {
      id: 5,
      pic: pic5,
      title: 'Order Now',
      text: `We are an application that provides a variety of services in the form of one app. Services can range from check-in services and payments at restaurants, bars, or other venues; to gas, grocery, and convenience store shopping; to fitness activities such as yoga or spin classes. All you need is your phone and you're set!`
    },
    {
      id: 6,
      pic: pic6,
      title: 'Order Now',
      text: `If you're looking to manage your social media, email marketing, and SEO, we've got you covered. We offer all the tools you need to run and grow your online business, all in one place. Our intuitive and feature-rich tools will help you get more likes, followers and keep track of your stats. Try us today - we're free!`
    },
    {
      id: 7,
      pic: pic7,
      title: 'Order Now',
      text: `You have to film, edit, and then create subtitles for your videos. That's why we created Video Content - All in one. With our solution, you can make high-quality videos in minutes with just a few clicks. Videos are automatically translated into the language of your choice and downloaded as an MP4 file in 1080p HD.`
    },
    {
      id: 8,
      pic: pic8,
      title: 'Order Now',
      text: `Audio offers a variety of services to suit all your music needs. From the musician to the composer, we offer the best services for all of your audio production needs. We have been in business for over 10 years and have worked with many customers across different industries.`
    },
    {
      id: 9,
      pic: pic9,
      title: 'Order Now',
      text: `Our AI-powered analytics provides insights on online traffic, social media engagement, and conversion rates. Our software can be used by digital marketers to identify the best promotional strategies for their clients. They can even generate ROI reports for their marketing campaigns and see how their marketing efforts are doing in real-time.`
    }
  ]

  return (
    <div className="section" id="cases_block">
      <Title
        type="title theme"
        text="Our Services"
      />
      <div className="container">
        <h2 className="subtitle w-70 ma text-center medium">We have a wide array of skilled developers on our team, ready to work for you. From new teams needing help with small projects to large-scale enterprises that need full-time staff, we have the plan for you.
        </h2>
        <div className="s-40"></div>
        <div className="flex rowrap jcsb w-90 ma">
          {slides.map(screen => (
            <div className="w-30 r-8 shadow-sm my-5 oh">
              <>
                <img
                  className='w-100'
                  src={screen.pic}
                  alt={'pic'}
                />
                <div className="p-5 bg-white">
                  <h3 className="text c-medium text-left h-60"><div className="pl-3 pr-2">{screen.text}</div></h3>
                  <div className="flex jcc">
                    <AnchorLink className="btn" href="#contact_block">{screen.title}</AnchorLink>
                  </div>
                  <div className="s-10"></div>
                </div>
              </>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
}

export default Cases
