import React from 'react'

import { FcBusinessman, FcBiotech, FcCollaboration } from "react-icons/fc"

import AnchorLink from 'react-anchor-link-smooth-scroll'
import Title from './Title'


function Features() {
  return (
    <div className="section" id="feature_block">
      <Title
        type="title theme"
        text="It's time to outsource"
      />
      <div className="container">
        <h2 className="subtitle w-80 ma text-center c-medium">Outsourcing is an efficient way to maintain a lean and competitive team. Let Devs Team Cloud take care of your project's development needs, so you can focus on what's important.
        </h2>
        <div className="s-40"></div><div className="s-40"></div>
        <div className="flex jcsa w-90 ma">
          <div className="w-30 text-center">
            <span className="a-icon large bg-intro br-lite br-two r-50 mb-5">
              <FcCollaboration size={40} />
            </span>
            <h3 className="subtitle">High-quality development at competitive prices</h3>
            <p className="text medium">
              We offer competitive rates for top-quality work. Whether you're looking for onsite or remote work, we have the team for you.</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon large bg-intro br-lite br-two r-50 mb-5">
              <FcBiotech size={40} />
            </span>
            <h3 className="subtitle">The best developers<br />in the world</h3>
            <p className="text medium">
              Have a project that needs expertise in a certain language or skill set? No worries! We have a full team of world-class developers waiting for your request.</p>
          </div>
          <div className="w-30 text-center">
            <span className="a-icon large bg-intro br-lite br-two r-50 mb-5">
              <FcBusinessman className='c-premium' size={48} />
            </span>
            <h3 className="subtitle">Your personal outsourcers, just waiting to take your call</h3>
            <p className="text medium">
              We make it easy to get started with simple signup and communication through our dashboard. Request a developer today and they'll be at your service in minutes!</p>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="text-center">
          <AnchorLink href="#contact_block" className='btn btn--premium'>Connect Now</AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default Features
