import React from 'react'
import Form from './Form'

function Contact() {
  return (
    <div className="section dark" id="contact_block">
      <div className="container text-center">
        <Form />
      </div>
    </div>
  );
}

export default Contact
