import React from 'react'
import Header from './components/Header'
import Intro from './components/Intro'
import About from './components/About'
import Info from './components/Info'
import Features from './components/Features'
import Cases from './components/Cases'
import Cta from './components/Cta'
import Contact from './components/Contact'
import Footer from './components/Footer'
import './styles/custom.sass'

function App() {
  return (
    <>
      <Header />
      <Intro />
      <About />
      <Info />
      <Features />
      <Cta />
      <Cases />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
