import React from 'react'
import { HiCheckBadge } from "react-icons/hi2"
import Title from './Title'
import feat01 from '../assets/svg/feat-001.svg'
import feat02 from '../assets/svg/feat-002.svg'

function Info() {
  return (
    <div className="section w-100" id="info_block">
      <Title
        type="title theme"
        text="What you get with DevsTeam Cloud"
      />
      <div className="container">
        <div className="flex w-80 rowrap ma aic">
          <div className="s-20"></div>
          <div className="flex jcsb aic">
            <div className="w-60 m-5 p-5 bg-intro r-40">
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud offers competitive rates and has established relationships with some of the most talented and experienced IT freelancers in the world.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud offers unparalleled customer service and is committed to providing a personalized experience to each of its clients.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">With Devs Team Cloud, you can fill any IT position quickly and easily without having to deal with the hassle of a traditional recruiting agency.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud offers access to a large pool of highly qualified IT professionals and takes the time to understand your exact requirements to ensure you get the perfect fit for your project.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud eliminates the need for expensive contracts, and allows you to pay for only the hours you need for your project.
                  </div>
                </span>
              </div>
            </div>
            <div className="w-30">
              <img src={feat01} alt="DevsTeam" />
            </div>
          </div>
          <div className="flex jcsb aic">
            <div className="w-30">
              <img src={feat02} alt="DevsTeam" />
            </div>
            <div className="w-60 m-5 p-5 bg-intro r-40">
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud offers flexible payment methods and is committed to helping you save time and money.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud’s experienced team of IT professionals will provide you with the best advice and guidance for your project.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud is an innovative platform, offering cloud-based solutions and cutting-edge technologies to help you get the best out of your project.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud provides instant access to the best IT professionals and allows you to find the right candidate for your project in minutes.
                  </div>
                </span>
              </div>
              <div className="flex">
                <span className="a-icon small bg-white r-50 ra">
                  <HiCheckBadge className='c-premium' size={32} />
                </span>
                <span className="text icon medium">
                  <div className="c-medium pl-2 mb-5 pt-2">Devs Team Cloud is committed to providing a secure and reliable platform to ensure the safety and security of your data while working with IT freelancers.
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
