import React from 'react'

const Form = () => {

  const host = window.location.hostname

  return (
    <div id="contactForm">
      <div className="w-80 ma">
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
        <iframe title="/" src={`https://airtable.com/embed/shrHaaNRIGjwzBh1w?backgroundColor=transparent&prefill_Origin=${host}&hide_Origin=true`} frameborder="0" width="100%" style={{ textAlign: 'center', minHeight: '200vh', overflowY: 'hidden' }}></iframe>
      </div>
    </div>
  )
}
export default Form
