import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Footer() {
  return (
    <footer className="footer" id="footer_block">
      <div className="py-5">
        <div className="f-bottom flex jcc aic h-20">
          <span className='text c-lite mt-4'><AnchorLink href="#root" className='text c-lite'>DevsTeam</AnchorLink> - &copy;2022. All Rights Reserved</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer
