import React from 'react'
import pic from '../assets/svg/pic.svg'

function Intro() {
  return (
    <div className="intro">
      <div className="container">
        <div className="flex rowrap jcsa w-100">
          <div className="w-50 text-left pl-5 pt-5 mt-5">
            <h1 className="intro__title">We offer all-in-one solutions from talented IT freelancers to suit your needs.</h1>
            <div className="s-20"></div>
            <p className="intro__text w-80 text-left">Devs Team Cloud is a freelancing marketplace that connects top-level developers with businesses from around the world.</p>
          </div>
          <img src={pic} alt="pic" className="w-50 text-right" />
        </div>
      </div>
    </div>
  )
}

export default Intro
